.site-footer {
    text-align: center;

    @include bp-medium {
        .site-footer__copyright {
            text-align: left;
        }
        .site-footer__media {
            text-align: right;
        }
    }
}

.site-footer__media img {
    max-width: 30px;
}