.site-header {
    background: {
        repeat: no-repeat;
        size: cover;
        position: 100px center;
        color: $black-bg;
        image: url(../assets/images/header.png);
    }
    min-height: 150px;
    @extend .z-depth-3;
    
    .brand-logo {
        img {
            padding: 0 15px;
            max-width: 280px;
        }
    }

    .nav-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: wrap column;

        @include bp-medium {
            flex-direction: row;
        }
    }

    .nav-mobile {
        margin: 15px 0 0;
        @include bp-medium {
            margin: 0;
        }

        .btn {
            border: 1px solid white;
            box-shadow: none;
        }
    }
}