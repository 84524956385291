.section__hero {
    padding: 0;
    .video-bg {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    .parallax-container {
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;

        background-image: url(../assets/images/herobg.jpg);
        background: {
            repeat: no-repeat;
            size: cover;
            attachment: fixed;
        }
    }
}

.section__memoir,
.section__about {
    .section__title h2 {
        text-align: center;
        text-transform: uppercase;
        &:before {
            margin: auto;
            left: 0;
            right: 0;
        }
    }
}

.section__about {
    padding-bottom: 60px;
}

.section__feeding .collapsible-header {
    background-image: url(../assets/images/tangledfeeding.jpg);
}

.section__legacy .collapsible-header {
    background-image: url(../assets/images/spacelegacy.jpg);
}

.section__oneprice .collapsible-header {
    background-image: url(../assets/images/oneprice.jpg);
}

.section__minors .collapsible-header {
    background-image: url(../assets/images/manageminors.jpg);
}

.section__space .collapsible-header {
    background-image: url(../assets/images/strugglespace.jpg);
}

.section__wandering .collapsible-header {
    background-image: url(../assets/images/wanderingwoman.jpg);
}

.section__perform .collapsible-header {
    background-image: url(../assets/images/perform.jpg);
}

.section__upward .collapsible-header {
    background-image: url(../assets/images/upwardwego.jpg);
}

.section__role .collapsible-header {
    background-image: url(../assets/images/vastrole.jpg);
}

.section__wheelchair .collapsible-header {
    background-image: url(../assets/images/wheelchair.jpg);
}