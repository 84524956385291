.section {
    padding: 60px 0 0;
    text-align: justify;

    &__title h2 {
        margin-top: 0;
        font-size: 26px;
        letter-spacing: 0.5px;
        position: relative;
        padding-bottom: 10px;

        &:before {
            content: "";
            width: 100px;
            height: 2px;
            background: $red;
            position: absolute;
            bottom: 0;
        }
    }
}

.section .collapsible {
    &,
    &-header,
    &-body {
        border: 0;
    }
    box-shadow: none;
    margin: 0;

    &-header {
        height: 350px;
        padding: 0;
        background: {
            repeat: no-repeat;
            size: cover;
        }
        justify-content: center;
        flex-direction: column;
        color: white;
        
        &,
        > span {
            position: relative;
        }

        > span {
            display: block;
            border: 1px solid white;
            padding: 16px;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-size: 20px;
        }

        .material-icons {
            transform: rotate(90deg);
            font-size: 3rem;
        }
    }
}

.section-collapsible .collapsible {
    li {
        position: relative;

        &:not(.active) .collapsible-header:before {
            content: "";
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
        }

        &:nth-child(odd):not(.active){
            .collapsible-header:before {
                background: rgba($black-bg, 0.5);
            }
        }
        &:nth-child(even):not(.active) {
            .collapsible-header:before {
                background: rgba($red-bg, 0.5);
            }
        }

        &.active:nth-child(odd) .collapsible-header {
            background-color: rgba($black-bg, 0.5);
        }
        &.active:nth-child(even) .collapsible-header {
            background-color: rgba($red-bg, 0.5);
        }

        &.active .collapsible-header {
            > span,
            > .material-icons {
                display: none;
            }
        }
    }
}