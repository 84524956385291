html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}

body {
    font-family: 'PT Sans', sans-serif;
}

// layout
.main-wrapper {
    min-height: 100%;
}